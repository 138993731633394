.contact-container form,
.contact-container fieldset,
.contact-container .button-primary {
  margin-bottom: 0; }

.success-message {
  text-align: center;
  color: #fff;
  background: green;
  padding: 4px;
  margin-top: 16px;
  animation: fadein 3s;
  -moz-animation: fadein 3s;
  /* Firefox */
  -webkit-animation: fadein 3s;
  /* Safari and Chrome */
  -o-animation: fadein 3s;
  /* Opera */ }

@keyframes fadein {
  0% {
    opacity: 0; }
  10% {
    opacity: 0.1; }
  20% {
    opacity: 0.2; }
  35% {
    opacity: 0.3; }
  50% {
    opacity: 0.5; }
  75% {
    opacity: 0.7; }
  90% {
    opacity: 0.9; }
  100% {
    opacity: 1; } }

.close {
  float: right;
  margin-right: 8px;
  cursor: pointer;
  font-size: 24px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  margin-top: 3px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center; }
  .close:hover {
    background: #ddd; }
