.service-block {
  padding: 32px 16px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border: 1px solid #e4ecf6;
  background: white;
  text-decoration: none;
  color: #333;
}

.service-block:hover {
  box-shadow: 0 15px 40px -20px rgb(44, 47, 58);
  -webkit-transform: translate(0px, -10px);
  -ms-transform: translate(0px, -10px);
  transform: translate(0px, -10px);
}

.subheading,
.offshore-container p {
  padding-left: 24px;
}

.software-intern-icon {
  height: 100px;
}

.analytics-page {
  .analytics-hero-image {
    background-repeat: no-repeat;
    background-position: right;
  }
  .technologies {
    > div {
      margin-bottom: 24px;
    }
  }
}
