.header-bar {
  position: fixed;
  width: 100%;
  top: 0;
  background: #fff;
  z-index: 99;
  box-shadow: 0 15px 40px -20px rgba(40, 44, 63, 0.15);
}
body {
  padding-top: 100px;
}
.navigation {
  padding: 20px 0;
  z-index: 10;
}

.navigation-links {
  .navigation-link:hover {
    color: white;
    background: #e22a29;
  }
  .navigation-link {
    color: #333;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
    transition: all 0.2s ease-in;
    display: inline;
    padding: 1rem 2rem;
    cursor: pointer;
    &.activePage {
      color: white;
      background: #1c375d;
    }
  }
}
.light .navigation-links .navigation-link {
  color: white;
}

.nav-toggle-icon {
  display: none;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.menu-icon {
  width: 36px;
  height: 36px;
}

@media screen and (max-width: 600px) {
  .nav-toggle-icon {
    display: inline;
  }
  .navigation-links.hide-menu {
    display: none;
  }
  .navigation-links.show-menu {
    z-index: 99;
    display: block;
    background: #ebeeef;
    position: absolute;
    width: 100%;
  }
  .navigation-links.show-menu a {
    display: block;
  }
}
