@import '../../styles/_theme';
/* Styling */
.timeline {
  position: relative;
  list-style: none;
  &:before {
    background-color: black;
    content: '';
    margin-left: -1px;
    position: absolute;
    top: 0;
    left: 2em;
    width: 2px;
    height: 100%;
  }
}
.timeline-event {
  position: relative;
  &:hover .timeline-event-icon {
    transform: rotate(-45deg);
    background-color: $color-primary;
  }
  &:hover .timeline-event-thumbnail {
    background-color: $color-primary;
    box-shadow: inset 40em 0 0 0 $color-primary;
  }
  p:last-child {
    font-size: 1.2em;
  }
}

.timeline-event-copy {
  padding: 1.5em;
  position: relative;
  top: -1.875em;
  left: 4em;
  h3 {
    font-size: 1.75em;
  }
  p:not(.timeline-event-thumbnail) {
    padding-bottom: 0em;
    margin-bottom: 0;
  }
}

.timeline-event-icon {
  transition: transform 0.2s ease-in;
  transform: rotate(45deg);
  background-color: $color-tertiary;
  outline: 10px solid white;
  display: block;
  margin: 0.5em 0.5em 0.5em -0.5em;
  position: absolute;
  top: 0;
  left: 2em;
  width: 1em;
  height: 1em;
}
.timeline-event-thumbnail {
  transition: box-shadow 0.5s ease-in 0.1s;
  color: white;
  font-size: 1.3em;
  background-color: $color-tertiary;
  box-shadow: inset 0 0 0 0em #ef795a;
  display: inline-block;
  margin-bottom: 1.2em;
  margin-top: -0.5em;
  padding: 0.25em 1em 0.2em 1em;
}

@media screen and (max-width: 600px) {
  .timeline-event-copy {
    padding: 0;
    width: calc(100% - 5em);
  }
}
