.container {
  margin: 0 auto;
  // max-width: 112.0rem;
  max-width: 1170px;
  // padding: 0 2.0rem;
  position: relative;
  width: 100%;
}
.container-fluid {
  width: 100%;
  padding: 0;
  margin: 0 auto;
}
