@import url(https://fonts.googleapis.com/css?family=Poppins:400,500);
@import url(https://fonts.googleapis.com/css?family=Merriweather:300,700);
dl,
ol,
ul {
  list-style: none;
  margin-top: 0;
  padding-left: 0; }
  dl dl,
  dl ol,
  dl ul,
  ol dl,
  ol ol,
  ol ul,
  ul dl,
  ul ol,
  ul ul {
    font-size: 90%;
    margin: 1.5rem 0 1.5rem 3.0rem; }

ol {
  list-style: decimal inside; }

ul {
  list-style: circle inside; }

blockquote {
  border-left: 0.3rem solid #d1d1d1;
  margin-left: 0;
  margin-right: 0;
  padding: 1rem 1.5rem; }
  blockquote *:last-child {
    margin-bottom: 0; }

input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
textarea,
select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 0.1rem solid #d1d1d1;
  border-radius: .4rem;
  box-shadow: none;
  box-sizing: inherit;
  height: 3.8rem;
  padding: .6rem 1.0rem;
  width: 100%; }
  input[type='email']:focus,
  input[type='number']:focus,
  input[type='password']:focus,
  input[type='search']:focus,
  input[type='tel']:focus,
  input[type='text']:focus,
  input[type='url']:focus,
  textarea:focus,
  select:focus {
    border-color: #e22a29;
    outline: 0; }

select {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="#d1d1d1" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>') center right no-repeat;
  padding-right: 3.0rem; }
  select:focus {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="#9b4dca" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>'); }

textarea {
  min-height: 6.5rem; }

label,
legend {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: .5rem; }

fieldset {
  border-width: 0;
  padding: 0; }

input[type='checkbox'],
input[type='radio'] {
  display: inline; }

.label-inline {
  display: inline-block;
  font-weight: normal;
  margin-left: .5rem; }

b,
strong {
  font-weight: bold; }

p {
  margin-top: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  letter-spacing: -.1rem;
  margin-bottom: 2.0rem;
  margin-top: 0; }

h1 {
  font-size: 4.6rem;
  line-height: 1.2; }

h2 {
  font-size: 3.6rem;
  line-height: 1.25; }

h3 {
  font-size: 2.8rem;
  line-height: 1.3; }

h4 {
  font-size: 2.2rem;
  letter-spacing: -.08rem;
  line-height: 1.35; }

h5 {
  font-size: 1.8rem;
  letter-spacing: -.05rem;
  line-height: 1.5; }

h6 {
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 1.4; }

.button,
button,
dd,
dt,
li {
  margin-bottom: 1.0rem; }

fieldset,
input,
select,
textarea {
  margin-bottom: 1.5rem; }

blockquote,
dl,
figure,
form,
ol,
p,
pre,
table,
ul {
  margin-bottom: 2.5rem; }

.clearfix:after {
  clear: both;
  content: ' ';
  display: table; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.button,
button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  background-color: #e22a29;
  border: 0.1rem solid #e22a29;
  border-radius: .4rem;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 700;
  height: 3.8rem;
  letter-spacing: .1rem;
  line-height: 3.8rem;
  padding: 0 3.0rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap; }
  .button:focus, .button:hover,
  button:focus,
  button:hover,
  input[type='button']:focus,
  input[type='button']:hover,
  input[type='reset']:focus,
  input[type='reset']:hover,
  input[type='submit']:focus,
  input[type='submit']:hover {
    background-color: #333;
    border-color: #333;
    color: #fff;
    outline: 0; }
  .button[disabled],
  button[disabled],
  input[type='button'][disabled],
  input[type='reset'][disabled],
  input[type='submit'][disabled] {
    cursor: default;
    opacity: .5; }
    .button[disabled]:focus, .button[disabled]:hover,
    button[disabled]:focus,
    button[disabled]:hover,
    input[type='button'][disabled]:focus,
    input[type='button'][disabled]:hover,
    input[type='reset'][disabled]:focus,
    input[type='reset'][disabled]:hover,
    input[type='submit'][disabled]:focus,
    input[type='submit'][disabled]:hover {
      background-color: #e22a29;
      border-color: #e22a29; }
  .button.button-outline,
  button.button-outline,
  input[type='button'].button-outline,
  input[type='reset'].button-outline,
  input[type='submit'].button-outline {
    background-color: transparent;
    color: #e22a29; }
    .button.button-outline:focus, .button.button-outline:hover,
    button.button-outline:focus,
    button.button-outline:hover,
    input[type='button'].button-outline:focus,
    input[type='button'].button-outline:hover,
    input[type='reset'].button-outline:focus,
    input[type='reset'].button-outline:hover,
    input[type='submit'].button-outline:focus,
    input[type='submit'].button-outline:hover {
      background-color: transparent;
      border-color: #333;
      color: #333; }
    .button.button-outline[disabled]:focus, .button.button-outline[disabled]:hover,
    button.button-outline[disabled]:focus,
    button.button-outline[disabled]:hover,
    input[type='button'].button-outline[disabled]:focus,
    input[type='button'].button-outline[disabled]:hover,
    input[type='reset'].button-outline[disabled]:focus,
    input[type='reset'].button-outline[disabled]:hover,
    input[type='submit'].button-outline[disabled]:focus,
    input[type='submit'].button-outline[disabled]:hover {
      border-color: inherit;
      color: #e22a29; }
  .button.button-clear,
  button.button-clear,
  input[type='button'].button-clear,
  input[type='reset'].button-clear,
  input[type='submit'].button-clear {
    background-color: transparent;
    border-color: transparent;
    color: #e22a29; }
    .button.button-clear:focus, .button.button-clear:hover,
    button.button-clear:focus,
    button.button-clear:hover,
    input[type='button'].button-clear:focus,
    input[type='button'].button-clear:hover,
    input[type='reset'].button-clear:focus,
    input[type='reset'].button-clear:hover,
    input[type='submit'].button-clear:focus,
    input[type='submit'].button-clear:hover {
      background-color: transparent;
      border-color: transparent;
      color: #333; }
    .button.button-clear[disabled]:focus, .button.button-clear[disabled]:hover,
    button.button-clear[disabled]:focus,
    button.button-clear[disabled]:hover,
    input[type='button'].button-clear[disabled]:focus,
    input[type='button'].button-clear[disabled]:hover,
    input[type='reset'].button-clear[disabled]:focus,
    input[type='reset'].button-clear[disabled]:hover,
    input[type='submit'].button-clear[disabled]:focus,
    input[type='submit'].button-clear[disabled]:hover {
      color: #e22a29; }

.wrapper {
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto; }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem; }

.row {
  box-sizing: border-box;
  display: -moz-flex;
  display: flex;
  -moz-flex: 0 1 auto;
  flex: 0 1 auto;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.row.reverse {
  -moz-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.col.reverse {
  -moz-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.col-xs {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: auto; }

.col-xs-1 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

.col-xs-2 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

.col-xs-3 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 25%;
  max-width: 25%; }

.col-xs-4 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

.col-xs-5 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

.col-xs-6 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 50%;
  max-width: 50%; }

.col-xs-7 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

.col-xs-8 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

.col-xs-9 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 75%;
  max-width: 75%; }

.col-xs-10 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

.col-xs-11 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

.col-xs-12 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 100%;
  max-width: 100%; }

.col-xs-offset-1 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 8.33333%; }

.col-xs-offset-2 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 16.66667%; }

.col-xs-offset-3 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 25%; }

.col-xs-offset-4 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 33.33333%; }

.col-xs-offset-5 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 41.66667%; }

.col-xs-offset-6 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 50%; }

.col-xs-offset-7 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 58.33333%; }

.col-xs-offset-8 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 66.66667%; }

.col-xs-offset-9 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 75%; }

.col-xs-offset-10 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 83.33333%; }

.col-xs-offset-11 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 91.66667%; }

.col-xs-offset-12 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 100%; }

.col-xs {
  -moz-flex-grow: 1;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%; }

.start-xs {
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  text-align: start; }

.center-xs {
  -moz-justify-content: center;
  justify-content: center;
  text-align: center; }

.end-xs {
  -moz-justify-content: flex-end;
  justify-content: flex-end;
  text-align: end; }

.top-xs {
  -moz-align-items: flex-start;
  align-items: flex-start; }

.middle-xs {
  -moz-align-items: center;
  align-items: center; }

.bottom-xs {
  -moz-align-items: flex-end;
  align-items: flex-end; }

.around-xs {
  -moz-justify-content: space-around;
  justify-content: space-around; }

.between-xs {
  -moz-justify-content: space-between;
  justify-content: space-between; }

.first-xs {
  order: -1; }

.last-xs {
  order: 1; }

@media only screen and (min-width: 48em) {
  .container {
    width: 46rem; }
  .col-sm {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto; }
  .col-sm-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 25%;
    max-width: 25%; }
  .col-sm-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 50%;
    max-width: 50%; }
  .col-sm-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 75%;
    max-width: 75%; }
  .col-sm-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 100%;
    max-width: 100%; }
  .col-sm-offset-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.33333%; }
  .col-sm-offset-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.66667%; }
  .col-sm-offset-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%; }
  .col-sm-offset-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.33333%; }
  .col-sm-offset-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66667%; }
  .col-sm-offset-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%; }
  .col-sm-offset-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.33333%; }
  .col-sm-offset-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66667%; }
  .col-sm-offset-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%; }
  .col-sm-offset-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333%; }
  .col-sm-offset-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66667%; }
  .col-sm-offset-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%; }
  .col-sm {
    -moz-flex-grow: 1;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .start-sm {
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start; }
  .center-sm {
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-sm {
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end; }
  .top-sm {
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-sm {
    -moz-align-items: center;
    align-items: center; }
  .bottom-sm {
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-sm {
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-sm {
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-sm {
    order: -1; }
  .last-sm {
    order: 1; } }

@media only screen and (min-width: 62em) {
  .container {
    width: 61rem; }
  .col-md {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto; }
  .col-md-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 25%;
    max-width: 25%; }
  .col-md-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 50%;
    max-width: 50%; }
  .col-md-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 75%;
    max-width: 75%; }
  .col-md-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 100%;
    max-width: 100%; }
  .col-md-offset-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.33333%; }
  .col-md-offset-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.66667%; }
  .col-md-offset-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%; }
  .col-md-offset-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.33333%; }
  .col-md-offset-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66667%; }
  .col-md-offset-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%; }
  .col-md-offset-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.33333%; }
  .col-md-offset-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66667%; }
  .col-md-offset-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%; }
  .col-md-offset-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333%; }
  .col-md-offset-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66667%; }
  .col-md-offset-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%; }
  .col-md {
    -moz-flex-grow: 1;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .start-md {
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start; }
  .center-md {
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-md {
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end; }
  .top-md {
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-md {
    -moz-align-items: center;
    align-items: center; }
  .bottom-md {
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-md {
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-md {
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-md {
    order: -1; }
  .last-md {
    order: 1; } }

@media only screen and (min-width: 75em) {
  .container {
    width: 71rem; }
  .col-lg {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto; }
  .col-lg-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 25%;
    max-width: 25%; }
  .col-lg-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 50%;
    max-width: 50%; }
  .col-lg-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 75%;
    max-width: 75%; }
  .col-lg-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 100%;
    max-width: 100%; }
  .col-lg-offset-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.33333%; }
  .col-lg-offset-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.66667%; }
  .col-lg-offset-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%; }
  .col-lg-offset-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.33333%; }
  .col-lg-offset-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66667%; }
  .col-lg-offset-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%; }
  .col-lg-offset-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.33333%; }
  .col-lg-offset-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66667%; }
  .col-lg-offset-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%; }
  .col-lg-offset-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333%; }
  .col-lg-offset-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66667%; }
  .col-lg-offset-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%; }
  .col-lg {
    -moz-flex-grow: 1;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .start-lg {
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start; }
  .center-lg {
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-lg {
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end; }
  .top-lg {
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-lg {
    -moz-align-items: center;
    align-items: center; }
  .bottom-lg {
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-lg {
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-lg {
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-lg {
    order: -1; }
  .last-lg {
    order: 1; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif !important; }

html {
  font-size: 1em; }

body,
input,
textarea,
select {
  background-color: white;
  font-family: 'Merriweather', sans-serif !important;
  font-weight: 400;
  line-height: 1.45;
  color: #444; }

label,
legend {
  font-size: 1rem; }

p {
  font-weight: 300;
  margin-bottom: 1.3em;
  font-size: 1.1em;
  line-height: 1.9em; }

h1,
h2,
h3,
h4 {
  margin: 1.414em 0 0.5em;
  font-weight: inherit;
  line-height: 1.2; }

h1 {
  margin-top: 0;
  font-size: 3.157em; }

h2 {
  font-size: 2.369em; }

h3 {
  font-size: 1.777em; }

h4 {
  font-size: 1.333em; }

small,
.font_small {
  font-size: 0.75em; }

.container {
  margin: 0 auto;
  max-width: 1170px;
  position: relative;
  width: 100%; }

.container-fluid {
  width: 100%;
  padding: 0;
  margin: 0 auto; }

html {
  font-size: 100% !important; }

body {
  background: #fff;
  margin: 0;
  padding: 0;
  background: #fefefe; }

.text-center {
  text-align: center; }

.blendWithNavbar .boxx {
  padding: 4rem 0; }

.boxx {
  padding: 2rem 0; }
  .boxx h3:nth-child(1),
  .boxx h2:nth-child(1) {
    margin-top: 0px; }

.main-content {
  margin-top: 80px; }

.m-t-0 {
  margin-top: 0 !important; }

.m-b-0 {
  margin-bottom: 0 !important; }

.m-b-8 {
  margin-bottom: 8px !important; }

.card {
  padding: 40px 60px;
  background-color: black;
  color: #1c375d; }

.service-link {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
  pointer-events: none;
  background: #fff !important;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 1px 0px 1px rgba(10, 10, 10, 0.1);
  border-radius: 4px;
  border: none !important; }

.project-link,
.service-link {
  background-color: #fff;
  border: 1px solid #555;
  margin: 8px 4px;
  height: 274px;
  border-radius: 4px;
  padding: 8px;
  color: #333;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 0.5px 0px 0.5px rgba(10, 10, 10, 0.1); }
  .project-link p,
  .service-link p {
    text-transform: none;
    margin: 0 !important;
    font-size: 20px; }
  .project-link small,
  .service-link small {
    font-size: 14px; }
  .project-link div,
  .service-link div {
    text-decoration: none; }
    .project-link div.dark,
    .service-link div.dark {
      color: #1c375d;
      text-decoration: none; }
  .project-link.nocolor,
  .service-link.nocolor {
    background: none; }
  .project-link.nocolor:hover,
  .service-link.nocolor:hover {
    cursor: pointer;
    background: #333; }
    .project-link.nocolor:hover div,
    .service-link.nocolor:hover div {
      color: #fff; }
    .project-link.nocolor:hover.dark,
    .service-link.nocolor:hover.dark {
      color: #1c375d; }
    .project-link.nocolor:hover.dark:hover,
    .service-link.nocolor:hover.dark:hover {
      background-color: #e22a29; }
  .project-link.circle,
  .service-link.circle {
    border-radius: 50% 50%; }
  .project-link .project-link-title,
  .service-link .project-link-title {
    font-size: 1.5em;
    font-weight: 700;
    color: white; }
  .project-link .project-link-description,
  .service-link .project-link-description {
    font-size: 1em;
    color: white; }

.project-link:hover {
  box-shadow: 0 15px 40px -20px #2c2f3a;
  color: #fff; }

.FluidImage {
  -webkit-transition: -webkit-transform 5s ease-in;
  transition: -webkit-transform 5s ease-in;
  transition: transform 5s ease-in;
  transition: transform 5s ease-in, -webkit-transform 5s ease-in;
  overflow: hidden; }
  .FluidImage img {
    -webkit-transition: -webkit-transform 2s ease-in;
    transition: -webkit-transform 2s ease-in;
    transition: transform 2s ease-in;
    transition: transform 2s ease-in, -webkit-transform 2s ease-in; }
  .FluidImage img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }

.technologies > div {
  text-align: center; }

.technologies .icon-title {
  font-weight: 700;
  text-align: center; }

.technologies .icon-image {
  height: 75px; }

.technologies.worked-companies > div {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center; }

.technologies.worked-companies .icon-image {
  height: 80px;
  max-width: 100%;
  object-fit: contain; }

.no-margin {
  margin: 0; }

textarea {
  background: transparent !important; }

.icon-standard {
  width: 64px;
  height: 64px; }

.contact-subtitle {
  font-size: 14px; }

.contact-grid-container .icon-image {
  height: 75px; }

.contact-grid-container .icon-standard {
  width: 40px;
  height: 40px; }
  .contact-grid-container .icon-standard.lets-talk {
    width: 48px;
    height: 48px; }

.contact-grid-container .grid-title {
  margin-top: 0px;
  color: #333;
  letter-spacing: 1px; }

.contact-grid-container .grid-subtitle {
  color: #333;
  font-size: 14px;
  text-align: left; }

.contact-grid-container .mailto {
  color: #333;
  text-decoration: none;
  cursor: pointer; }

.footer .boxx {
  padding: 16px; }

#root {
  padding: 0; }

.banner-header {
  line-height: 1.6; }

.terms-conditions-link {
  text-decoration: none;
  color: #fff;
  float: right; }

.vertical-align-middle {
  vertical-align: middle; }

.d-flex {
  display: flex; }

.contact-icon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  vertical-align: middle; }
  .contact-icon.location-icon {
    width: 24px;
    height: 24px; }

.address {
  margin-top: -2px; }

.pl-4 {
  padding-left: 4px !important; }

@media screen and (max-width: 600px) {
  .boxx {
    padding: 8px !important; }
  .tnc-table th {
    max-width: 100px; } }

@media screen and (min-width: 600px) {
  .contact-us-container {
    display: flex;
    justify-content: center;
    align-items: center; }
  .contact-us-container a {
    margin-left: 16px; }
  .address-details {
    padding-left: 16px; } }

.service-block {
  padding: 32px 16px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border: 1px solid #e4ecf6;
  background: white;
  text-decoration: none;
  color: #333; }

.service-block:hover {
  box-shadow: 0 15px 40px -20px #2c2f3a;
  -webkit-transform: translate(0px, -10px);
  transform: translate(0px, -10px); }

.subheading,
.offshore-container p {
  padding-left: 24px; }

.software-intern-icon {
  height: 100px; }

.analytics-page .analytics-hero-image {
  background-repeat: no-repeat;
  background-position: right; }

.analytics-page .technologies > div {
  margin-bottom: 24px; }

.header-bar {
  position: fixed;
  width: 100%;
  top: 0;
  background: #fff;
  z-index: 99;
  box-shadow: 0 15px 40px -20px rgba(40, 44, 63, 0.15); }

body {
  padding-top: 100px; }

.navigation {
  padding: 20px 0;
  z-index: 10; }

.navigation-links .navigation-link:hover {
  color: white;
  background: #e22a29; }

.navigation-links .navigation-link {
  color: #333;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1rem;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  display: inline;
  padding: 1rem 2rem;
  cursor: pointer; }
  .navigation-links .navigation-link.activePage {
    color: white;
    background: #1c375d; }

.light .navigation-links .navigation-link {
  color: white; }

.nav-toggle-icon {
  display: none;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer; }

.menu-icon {
  width: 36px;
  height: 36px; }

@media screen and (max-width: 600px) {
  .nav-toggle-icon {
    display: inline; }
  .navigation-links.hide-menu {
    display: none; }
  .navigation-links.show-menu {
    z-index: 99;
    display: block;
    background: #ebeeef;
    position: absolute;
    width: 100%; }
  .navigation-links.show-menu a {
    display: block; } }

.side-by-side {
  display: flex;
  align-items: flex-start; }

.side-by-side .content-body img {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  margin: 4px 0;
  width: 100%; }

.sticky-right-content {
  padding-left: 16px !important; }

.sticky-left-content,
.sticky-right-content {
  top: 100px !important; }

.contact-container form,
.contact-container fieldset,
.contact-container .button-primary {
  margin-bottom: 0; }

.success-message {
  text-align: center;
  color: #fff;
  background: green;
  padding: 4px;
  margin-top: 16px;
  animation: fadein 3s;
  -moz-animation: fadein 3s;
  /* Firefox */
  -webkit-animation: fadein 3s;
  /* Safari and Chrome */
  -o-animation: fadein 3s;
  /* Opera */ }

@-webkit-keyframes fadein {
  0% {
    opacity: 0; }
  10% {
    opacity: 0.1; }
  20% {
    opacity: 0.2; }
  35% {
    opacity: 0.3; }
  50% {
    opacity: 0.5; }
  75% {
    opacity: 0.7; }
  90% {
    opacity: 0.9; }
  100% {
    opacity: 1; } }

@keyframes fadein {
  0% {
    opacity: 0; }
  10% {
    opacity: 0.1; }
  20% {
    opacity: 0.2; }
  35% {
    opacity: 0.3; }
  50% {
    opacity: 0.5; }
  75% {
    opacity: 0.7; }
  90% {
    opacity: 0.9; }
  100% {
    opacity: 1; } }

.close {
  float: right;
  margin-right: 8px;
  cursor: pointer;
  font-size: 24px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  margin-top: 3px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center; }
  .close:hover {
    background: #ddd; }

/* Styling */
.timeline {
  position: relative;
  list-style: none; }
  .timeline:before {
    background-color: black;
    content: '';
    margin-left: -1px;
    position: absolute;
    top: 0;
    left: 2em;
    width: 2px;
    height: 100%; }

.timeline-event {
  position: relative; }
  .timeline-event:hover .timeline-event-icon {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    background-color: #e22a29; }
  .timeline-event:hover .timeline-event-thumbnail {
    background-color: #e22a29;
    box-shadow: inset 40em 0 0 0 #e22a29; }
  .timeline-event p:last-child {
    font-size: 1.2em; }

.timeline-event-copy {
  padding: 1.5em;
  position: relative;
  top: -1.875em;
  left: 4em; }
  .timeline-event-copy h3 {
    font-size: 1.75em; }
  .timeline-event-copy p:not(.timeline-event-thumbnail) {
    padding-bottom: 0em;
    margin-bottom: 0; }

.timeline-event-icon {
  -webkit-transition: -webkit-transform 0.2s ease-in;
  transition: -webkit-transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
  transition: transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background-color: #1c375d;
  outline: 10px solid white;
  display: block;
  margin: 0.5em 0.5em 0.5em -0.5em;
  position: absolute;
  top: 0;
  left: 2em;
  width: 1em;
  height: 1em; }

.timeline-event-thumbnail {
  -webkit-transition: box-shadow 0.5s ease-in 0.1s;
  transition: box-shadow 0.5s ease-in 0.1s;
  color: white;
  font-size: 1.3em;
  background-color: #1c375d;
  box-shadow: inset 0 0 0 0em #ef795a;
  display: inline-block;
  margin-bottom: 1.2em;
  margin-top: -0.5em;
  padding: 0.25em 1em 0.2em 1em; }

@media screen and (max-width: 600px) {
  .timeline-event-copy {
    padding: 0;
    width: calc(100% - 5em); } }

.tech-container {
  border: 1px solid #ddd;
  padding: 15px;
  margin: 8px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row; }

.tech-container .icon-container {
  background-color: white;
  margin-right: 15px;
  width: 150px;
  flex-direction: column; }

.tech-container .icon-container p {
  margin-bottom: 0; }

.tech-container .desc {
  text-align: start; }

.duties-container {
  background: #fff;
  box-shadow: 0px 0px 5px 1px #ddd;
  padding: 16px; }
  .duties-container h4 {
    margin-top: 0; }
  .duties-container ul {
    padding-left: 24px; }

.skills span {
  background: #ddd;
  margin: 0 4px;
  padding: 8px;
  border-radius: 16px; }

.responsive-modal button[class^='closeButton'] {
  background: #fff !important; }

.required-header {
  margin-top: 2.5rem !important; }

.tnc-header {
  background-color: #3b9ed9 !important; }

.tnc-table {
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  border-radius: 3px; }

.tnc-table th {
  padding: 18px 12px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px; }

.tnc-table td {
  padding: 12px;
  border-left: 1px solid #fff; }

