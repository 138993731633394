.tnc-header {
  background-color: #3b9ed9 !important;
}
.tnc-table {
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  border-radius: 3px;
}
.tnc-table th {
  padding: 18px 12px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
}
.tnc-table td {
  padding: 12px;
  border-left: 1px solid #fff;
}
