@import url('https://fonts.googleapis.com/css?family=Poppins:400,500');
@import url('https://fonts.googleapis.com/css?family=Merriweather:300,700');

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif !important;
}

html {
  font-size: 1em;
}

body,
input,
textarea,
select {
  background-color: white;
  font-family: 'Merriweather', sans-serif !important;
  font-weight: 400;
  line-height: 1.45;
  color: #444;
}
label,
legend {
  font-size: 1rem;
}

p {
  font-weight: 300;
  margin-bottom: 1.3em;
  font-size: 1.1em;
  line-height: 1.9em;
}

h1,
h2,
h3,
h4 {
  margin: 1.414em 0 0.5em;
  font-weight: inherit;
  line-height: 1.2;
}

h1 {
  margin-top: 0;
  font-size: 3.157em;
}

h2 {
  font-size: 2.369em;
}

h3 {
  font-size: 1.777em;
}

h4 {
  font-size: 1.333em;
}

small,
.font_small {
  font-size: 0.75em;
}
