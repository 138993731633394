.tech-container {
  border: 1px solid #ddd;
  padding: 15px;
  margin: 8px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.tech-container .icon-container {
  background-color: white;
  margin-right: 15px;
  width: 150px;
  flex-direction: column;
}
.tech-container .icon-container p {
  margin-bottom: 0;
}
.tech-container .desc {
  text-align: start;
}
