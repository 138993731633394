@import '_imports';
html {
  font-size: 100% !important;
}
body {
  background: $color-initial;
  margin: 0;
  padding: 0;
  background: #fefefe;
}
.text-center {
  text-align: center;
}
// .row {
//   border: 1px dashed;
// }
// .box {
//   background: lightcyan;
// }

.blendWithNavbar {
  .boxx {
    padding: 4rem 0;
  }
}

.boxx {
  padding: 2rem 0;
  h3:nth-child(1),
  h2:nth-child(1) {
    margin-top: 0px;
  }
}
// .case-studies {
//   .boxx {
//     padding: 2rem 4rem;
//   }
// }
.main-content {
  margin-top: 80px;
}
.m-t-0 {
  margin-top: 0 !important;
}
.m-b-0 {
  margin-bottom: 0 !important;
}
.m-b-8 {
  margin-bottom: 8px !important;
}

.card {
  padding: 40px 60px;
  background-color: black;
  color: $color-tertiary;
}
.service-link {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  pointer-events: none;
  background: #fff !important;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 1px 0px 1px rgba(10, 10, 10, 0.1);
  border-radius: 4px;
  border: none !important;
}
.project-link,
.service-link {
  background-color: #fff;
  border: 1px solid #555;
  margin: 8px 4px;
  height: 274px;
  border-radius: 4px;
  padding: 8px;
  color: #333;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 0.5px 0px 0.5px rgba(10, 10, 10, 0.1);
  p {
    text-transform: none;
    margin: 0 !important;
    font-size: 20px;
  }
  small {
    font-size: 14px;
  }
  div {
    // color: $color-secondary;
    text-decoration: none;
    &.dark {
      color: $color-tertiary;
      text-decoration: none;
    }
  }

  &.nocolor {
    background: none;
  }
  &.nocolor:hover {
    cursor: pointer;
    background: $color-secondary;
    div {
      color: #fff;
    }

    &.dark {
      color: $color-tertiary;
    }
    &.dark:hover {
      background-color: $color-primary;
    }
  }

  &.circle {
    border-radius: 50% 50%;
  }
  .project-link-title {
    font-size: 1.5em;
    font-weight: 700;
    color: white;
  }
  .project-link-description {
    font-size: 1em;
    color: white;
  }
}
.project-link:hover {
  box-shadow: 0 15px 40px -20px rgb(44, 47, 58);
  color: #fff;
}
.FluidImage {
  transition: transform 5s ease-in;
  overflow: hidden;
  img {
    transition: transform 2s ease-in;
  }
  img:hover {
    transform: scale(1.2);
  }
}

.technologies {
  > div {
    text-align: center;
  }
  .icon-title {
    font-weight: 700;
    text-align: center;
  }
  .icon-image {
    height: 75px;
  }
  &.worked-companies {
    > div {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon-image {
      height: 80px;
      max-width: 100%;
      object-fit: contain;
    }
  }
}
.no-margin {
  margin: 0;
}
textarea {
  background: transparent !important;
}

.icon-standard {
  width: 64px;
  height: 64px;
}

.contact-subtitle {
  font-size: 14px;
}

.contact-grid-container {
  .icon-image {
    height: 75px;
  }
  .icon-standard {
    width: 40px;
    height: 40px;
    &.lets-talk {
      width: 48px;
      height: 48px;
    }
  }

  .grid-title {
    margin-top: 0px;
    color: #333;
    letter-spacing: 1px;
  }
  .grid-subtitle {
    color: #333;
    font-size: 14px;
    text-align: left;
  }
  .mailto {
    color: #333;
    text-decoration: none;
    cursor: pointer;
  }
}

.footer {
  .boxx {
    padding: 16px;
  }
}

#root {
  padding: 0;
}
.banner-header {
  line-height: 1.6;
}
.terms-conditions-link {
  text-decoration: none;
  color: #fff;
  float: right;
}
.vertical-align-middle {
  vertical-align: middle;
}
.d-flex {
  display: flex;
}
.contact-icon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  vertical-align: middle;
  &.location-icon {
    width: 24px;
    height: 24px;
  }
}
.address {
  margin-top: -2px;
}
.pl-4{
  padding-left: 4px !important;
}

@media screen and (max-width: 600px) {
  .boxx {
    padding: 8px !important;
  }
  .tnc-table th {
    max-width: 100px;
  }
}

@media screen and(min-width:600px) {
  .contact-us-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contact-us-container a {
    margin-left: 16px;
  }
  .address-details {
    padding-left: 16px;
  }
}
