.duties-container {
  background: #fff;
  box-shadow: 0px 0px 5px 1px #ddd;
  padding: 16px;
  h4 {
    margin-top: 0;
  }
  ul {
    padding-left: 24px;
  }
}
.skills {
  span {
    background: #ddd;
    margin: 0 4px;
    padding: 8px;
    border-radius: 16px;
  }
}
.responsive-modal {
  button[class^='closeButton'] {
    background: #fff !important;
  }
}
.required-header {
  margin-top: 2.5rem !important;
}
