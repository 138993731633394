.side-by-side {
  display: flex;
  align-items: flex-start;
}
.side-by-side .content-sidebar {
  // padding: 34px 0;
}
.side-by-side .content-body img {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  margin: 4px 0;
  width: 100%;
}
.sticky-right-content {
  padding-left: 16px !important;
}

.sticky-left-content,
.sticky-right-content {
  top: 100px !important;
}
